import { apiEndpoint } from "../config/api"

export default function CastApi(castID) {
    return new Promise((resolve, reject) => {
        console.log("env")
        console.log(process.env.NODE_ENV)
        const path = `${apiEndpoint()}/v1/casts/${castID}`
        console.log(path)
        let request = new XMLHttpRequest()
        request.open('GET', path, true)
        request.responseType = 'json'
        request.onload = () => {
            if (request.status !== 200) {
                resolve("error")
            }
            let json = request.response
            let json_string = JSON.stringify(json)
            let resData = JSON.parse(json_string)
            resolve(resData.Cast)
        }

        request.send()
    })
}