import axios, {AxiosPromise, AxiosRequestConfig} from "axios"

const url = "https://api.tavern.co.jp"

export type Cast = {
  CastID: string
  Author: {
    Name: string
    IconImageUri: string
  }
  ThumbnailImageUri: string
  Explanation: string
  StartedAt: Date
  EndedAt: Date
}

type GetCastResponse = {
  Cast: Cast
}

export const getCast = (castId: string): AxiosPromise<GetCastResponse> => {
  const options: AxiosRequestConfig<GetCastResponse> = {
    url: `${url}/v1/casts/${castId}`,
    method: "GET",
  }
  return axios(options)
}
