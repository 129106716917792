import { dynamicLinkConfig } from "../config/dynamicLink"
export class DynamicLinkBuilder{
    private castId:string
    private inflowIdentifier:string

    constructor(castId:string,inflowIdentifier:string){
        this.castId = castId
        this.inflowIdentifier = inflowIdentifier
    }

    public getUrl():URL{
        const{
            castDeepLink,
            subDomain,
            iosBundleID,
            androidPackageName,
            inflowUrl,
            isForcedRedirectEnabled,
            homepage
        } = dynamicLinkConfig
        const deepLink = `${castDeepLink}${`?id=${this.castId}`}`
        const iosFallbackUrl = `${inflowUrl}${`?inflow=${this.inflowIdentifier}&castId=${this.castId}`}`
        const fallbackUrl = homepage
        const url = new URL(subDomain)
        url.searchParams.append('link', deepLink)
        url.searchParams.append('ibi', iosBundleID)
        console.log(androidPackageName)
        url.searchParams.append('apn', androidPackageName)
          // url.searchParams.append('isi',this.iosStoreID)
        url.searchParams.append('ifl', iosFallbackUrl)
        url.searchParams.append('efr', String(Number(isForcedRedirectEnabled)))
        url.searchParams.append('ofl', fallbackUrl)
        url.searchParams.append('afl', fallbackUrl)
        return url
    }
}