//styleの定義
const nameStyle = {
  marginLeft:'auto',
  marginRight:'auto',
  marginTop:'auto',
  marginBottom:'auto',
  fontSize:'23px',
}

const imageBack = {
  height:'60px',
  marginLeft: '10px'
}

const imageMini = {
  height:'60px',
  marginLeft: '2%'
}

const imageSanten = {
  height:'60px',
  marginLeft: '30%'
}

const flex = {
  display: 'flex',
  height:'60px'
}

const underLine = {
  border:'thin solid #bbb'
}

export const NavigationBar = ({author}) => {
  const userName = author.name
  return(
    <>
      <div style={flex} class = "flex">
        {/* <img style={imageBack} src = {listenExit}/> */}
        {/* <img style={imageMini} src = {listenExit}/> */}
        <h1 style={nameStyle}>{userName}</h1>
        {/* <img style={imageSanten} src = {listenExit}/> */}
      </div>
      {/* <div style={underLine} class = "underLine"></div> */}
    </>
  )
}