import initialThumbnail from '../images/TavernNeon.png'

//styleの定義
const imageArea = {
  width : '280px',
  margin:'0 auto',
}
const participantsListStyle = {
  marginLeft:'70%',
  textDecoration:'underline',
}
export const UserSetImage = ({authorIconUri, ThumbnailImageUri}) => {
  let uri
  if (!ThumbnailImageUri){
    if(!authorIconUri){
      uri = initialThumbnail
    }else{
      uri = authorIconUri
    }
  }else{
    uri = ThumbnailImageUri
  }

  const imageBack = {
    backgroundImage: `url(${uri})`,
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize: 'cover',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box',
    borderRadius: '50%',  /* 角丸半径を50%にする(=円形にする) */
    width:'260px',
    height:'260px',
    objectFit:'cover',
    position: 'relative',
    zIndex:'0',
  }
  const imageBack1 = {
    backgroundImage: `url(${uri})`,
    backgroundPosition:'center',
    backgroundRepeat:'no-repeat',
    backgroundSize: 'cover',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box',
    border: 'solid 7px rgba(0,0,0,0.2)',
    overflow: 'hidden',
    borderRadius: '50%',  /* 角丸半径を50%にする(=円形にする) */
    width:'230px',
    height:'230px',
    objectFit:'cover',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    margin: 'auto',
    zIndex:'0',
  }
  return(
    <>
      <div style={imageArea}>
        <div style={imageBack}>
          <div style={imageBack1}/>
        </div>
      </div>
    </>
  )
}