import initialIcon from '../images/initialIcon.png'

//styleの定義
const nameStyle = {
  marginLeft: '2%',
  marginTop:'auto',
  marginBottom:'auto',
  fontSize:'15px',
}

const initialIconStyle = {
  borderRadius: '50%',
  height:'35px',
  marginLeft: '15px',
  marginTop:'auto',
  marginBottom:'auto',
}

const flex = {
  display: 'flex',
  height:'50px',  
}

export const UserSet = ({author}) => {
  let userIcon = author.iconImageUri
  const userId = author.id
  if (userIcon === undefined || userIcon === '') {
    userIcon = initialIcon
  }
  return(
    <>
      <div style={flex} class = "flex">
        <img src={userIcon} style={initialIconStyle}/>
        <div style={nameStyle}>@{userId}</div>
      </div>
    </>
  )
}