const prdApiEndpoint = "https://api.tavern.co.jp"
const devApiEndpoint = "https://dev.api.tavern.co.jp"

export const apiEndpoint = ():string => {
    if(process.env.NODE_ENV == "development"){
        return devApiEndpoint
    }else if(process.env.NODE_ENV == "production"){
        return prdApiEndpoint
    }else{
        return prdApiEndpoint
    }
}
