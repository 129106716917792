import React from 'react';

const barArea = {
  margin: '0 auto',
  marginTop: '100px',
  position: 'relative',
  width: '100%',
  height: '30px',
  display: 'flex'
  // maxWidth: 'var(--max-width)',
  // height: 'var(--height)',
}

const startTimerStyle = {
  position:'absolute',
  fontSize:'10px',
  left:'5%',
  marginTop:'0.5%',
}

const endTimerStyle = {
  position:'absolute',
  fontSize:'10px',
  right:'5%',
  marginTop:'0.5%',
}

const seekbar = (backgroundSize) => { 
  return {
  position: 'absolute',
  width: '70%',
  height: '6px',
  left: '0',
  right: '0',
  top: '5px',
  margin: 'auto',
  borderRadius: '5px',
  background: 'linear-gradient(#a3a3a3, #a3a3a3) no-repeat #eee',
  backgroundSize: String(backgroundSize) + '%' + '100%'
  }
}

function playTime (t) {
  let hms = ''
  const h = t / 3600 | 0
  const m = t % 3600 / 60 | 0
  const s = t % 60 | 0
  const z2 = (v) => {
    const s = '00' + v
    return s.substr(s.length - 2, 2)
  }
  if(h != 0){
    hms = h + ':' + z2(m) + ':' + z2(s)
  }else if(m != 0){
    hms = z2(m) + ':' + z2(s)
  }else{
    hms = '00:' + z2(s)
  }
  return hms
}

export const  BarArea = ({currentTime,durationTime,move}) => {
  const barProgress = Math.round((currentTime / durationTime) * 1000) / 10;
  const bar = React.useRef(null);

  function barTapped(e){
    const rect = bar.current.getBoundingClientRect()
    const position = rect.left + window.scrollX
    const offset = e.pageX - position
    const width = rect.right - rect.left
    const skipTime = Math.round(durationTime * (offset / width))
    move(skipTime)
    // move(Math.round(durationTime * (offset / width)))
  }
  return(
    <>
      <div style={barArea}>
        <div style={startTimerStyle}> {playTime(currentTime)} </div>
        <div style={seekbar(barProgress)} ref={bar} onClick={barTapped}/>
        <div style={endTimerStyle}> {playTime(durationTime)} </div>
      </div>
    </>
  )
}