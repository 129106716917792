import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ListenPage } from "./pages/ListenPage";
import { PresentListenPage } from './pages/PresentListenPage';
import { PreviewPage } from "./pages/Preview";
import { PrivateListenPage } from './pages/PrivateListenPage';

function App() {
    return (
        <div className="App">
            <Router>
                <Route exact path="/" component={<></>} />
                <Route path="/listen/:castId" component={ListenPage}/>
                <Route path="/presentListen/:castId" component={PresentListenPage}/>
                <Route path="/privateListen/:castId" component={PrivateListenPage}/>
                <Route path="/preview/:castId" component={PreviewPage} />
            </Router>
        </div>
    );
}

export default App;
