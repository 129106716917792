import backButton from '../images/backTime.png';
import playButton from '../images/playButton.png';
import pauseButton from '../images/pauseButton.png';
import skipButton from '../images/skipTime.png';

//styleの定義
const backButtonStyle = {
  position:'absolute',
  width: '50px',
  left: '10%',
  marginTop:'3%',
}

const playButtonStyle = {
  position:'absolute',
  width: '80px',
  left: '0',
  right: '0',
  margin: 'auto'
}

const skipButtonStyle = {
  position:'absolute',
  width: '50px',
  right: '10%',
  marginTop:'3%',
}

const area = {
  width:'100%',
  height:'80px',
  paddingTop:'40px',
  paddingBottom:'60px',
}
function backButtonTapped(){
  console.log("backButtonTapped")
}
export const PlayArea = ({isPlaying,play,pause,skip,back}) => {
  return(
    <>
    <div style={area}>
      <img style={backButtonStyle} src={backButton} onClick={back}></img>
      <img style={playButtonStyle} src={isPlaying? pauseButton:playButton} onClick={()=>{isPlaying? pause():play()}}></img>
      <img style={skipButtonStyle} src={skipButton} onClick={skip}></img>
    </div>
    </>
  )
}