import tavernlogo from '../images/Tavern_logo.png';

class DynamicLink {
    constructor(castID, userID, shareType) {
        this.subDomain = "https://taverniosapp.page.link/"
        this.deepLink = "https://taverniosapp/cast?id=" + castID
        this.iosBundleID = "Tavern.ios-app"
          // this.iosStoreID = "376771144"
        this.iosFallbackURL = "https://tavern.co.jp/inflow.html?inflow=web_" + shareType + "&userId=" + userID + "&castId=" + castID
        this.isForcedRedirectEnabled = "1"
        this.fallbackURL = "https://tavern.co.jp"
        this.url = this.create()
    }
    create() {
        let url = new URL(this.subDomain)
        url.searchParams.append('link', this.deepLink)
        url.searchParams.append('ibi', this.iosBundleID)
          // url.searchParams.append('isi',this.iosStoreID)
        url.searchParams.append('ifl', this.iosFallbackURL)
        url.searchParams.append('efr', this.isForcedRedirectEnabled)
        url.searchParams.append('ofl', this.fallbackURL)
        return url
    }
    getURL() {
    return this.url
    }
}

const tavernlogostyle = {
    position: 'absolute',
    height: "25px",
    top: '15px',
    bottom: '15px',
    left: '20px',
}

const container = {
    // "fontFamily": "Yu Gothic", YuGothic, Verdana, 'Hiragino Kaku Gothic ProN','Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, sans-serif,
    textAlign: "center",
    position: "relative",
    width: "100%",
    height: "55px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
}

/* openApp Button */
const openAppButton = {
    color: 'white',
    display: 'inline-block',
    fontSize: '12px',
    top: '15px',
    bottom: '15px',
    right: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    width: '80px',
    position: 'absolute',
    textDecoration: 'none',
    textTransform: 'uppercase',
    border: '1px solid transparent',
    borderRadius: '10px',
    outline: '1px solid',
    outlineColor: 'ragba(255, 255, 255, 0.5)',
    outlineOffset: '0px',
    textShadow: 'none',
    transition: 'all 1.2s cubic-bezier(0.2, 1, 0.2, 1)',
}

export const OpenAppBar = ({castId,userId,shareType}) => {
    const dynamiclink = new DynamicLink(castId,userId,shareType)
    const link = dynamiclink.create()
    return(
    <>
    <div style={container}>
        <img src={tavernlogo} style={tavernlogostyle}></img>
        <a style={openAppButton} href={link} >ログイン</a>
    </div>
    </>
    )
}