import React, {useRef, useState, useEffect, FC} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Hls from 'hls.js';
import '../style/ListenPage.css';

//コンポーネント
import { OpenAppBar } from '../component/OpenAppBar'
import { NavigationBar } from '../component/NavigationBar';
import { UserSet } from '../component/UserSet';
import { UserSetImage } from '../component/UserSetImage';
import { UserSetDescription } from '../component/UserSetDescription';
import { BarArea } from '../component/BarArea';
import { PlayArea } from '../component/PlayArea';

import CastApi from '../store/CastApi';
import initialThumbnai from '../images/TavernNeon.png';
import { Cast } from "../domain/Cast";
import { apiEndpoint } from "../config/api";

const listenView = {
  margin: 'auto',
  maxWidth: '450px',
  width: '100%',
}

type Props = {} & RouteComponentProps<{
  castId: string;
}>;

export const ListenPage: FC<Props> = (props: Props) => {
  const castID = props.match.params.castId
  const query = new URLSearchParams(props.location.search);
  const shareType = query.get("shareType")

  const path = `${apiEndpoint()}/v3/archive/${castID}/playlist.m3u8`

  const [castInfo, setCastInfo] = useState<Cast | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<string | null>(null)

  const [isPlaying, currentTime, durationTime, play, pause, move, skip, back] = useHlsAudio(path)

  useEffect(() =>{
    (async () => {
      setIsLoading(true)
      try {
        const res = await CastApi(castID)
        const castType = getCastType(res.StartedAt, res.EndedAt)
        if (res === "error") {
          props.history.push(`/privateListen/${castID}`)
        } else if (castType === "present") {
          props.history.push(`/presentListen/${castID}`)
        } else {
          setCastInfo({
            id: res.CastID,
            author: {
              id: res.Author.UserID,
              name: res.Author.Name,
              iconImageUri: res.Author.IconImageUri,
            },
            thumbnailImageUri: res.ThumbnailImageUri,
            explanation: res.Explanation,
            endedAt: res.EndedAt,
          })
        }
      } catch (e) {
        setIsError("error")
      } finally {
        setIsLoading(false)
      }

    })()
  },[castID])


  function listenViewImage(cast: Cast | null): any {
    let backgroundImage
    if (!cast?.thumbnailImageUri && !cast?.author.iconImageUri){
      backgroundImage = initialThumbnai
    } else if (!cast.thumbnailImageUri){
      backgroundImage = cast.author.iconImageUri
    } else{
      backgroundImage = cast.thumbnailImageUri
    }
    return {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition:'center',
      backgroundRepeat:'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      zIndex: '0',
      overflow: 'hidden',
    }
  }

  return (
    <>
      {
        isLoading ?
          <>loading...</> :
          <>
            {
              isError ?
                <>{isError}</> :
                <>
                  <div style = {listenView}>
                    <div id='listenViewImage' style={listenViewImage(castInfo)}>
                      <OpenAppBar
                        castId = {castID}
                        userId = {castInfo?.author.id}
                        shareType = {shareType}
                      />
                      <NavigationBar
                        author={castInfo?.author}
                      />
                      <UserSet
                        author={castInfo?.author}
                      />
                      <UserSetImage
                        authorIconUri={castInfo?.author.iconImageUri}
                        ThumbnailImageUri={castInfo?.thumbnailImageUri}
                      />
                      <UserSetDescription
                        Explanation={castInfo?.explanation}
                        EndedAt={castInfo?.endedAt}
                      />
                      <BarArea
                        currentTime={currentTime}
                        durationTime={durationTime}
                        move={move}
                      />
                      <PlayArea
                        isPlaying={isPlaying}
                        play={play}
                        pause={pause}
                        skip={skip}
                        back={back}
                      />
                    </div>
                  </div>
                </>
            }
          </>
      }
    </>
  )
}

const useHlsAudio = (url: string) => {
  const audioRef = useRef(new Audio())
  const audio = audioRef.current;
  const hls = new Hls()
  const [, _forceUpdate] = useState<boolean>(false)
  const forceUpdate = () => _forceUpdate(prevState => !prevState)

  React.useEffect(() => {
      if (Hls.isSupported()) {
          var hls = new Hls();
          hls.loadSource(url);
          hls.attachMedia(audio);
          hls.on(Hls.Events.MANIFEST_PARSED, function() {
          });
        }else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
          audio.src = url;
          audio.addEventListener('canplay', function () {
          });
        }
  }, [])

  React.useEffect(() => {
      audio.addEventListener("play", forceUpdate);
      audio.addEventListener("pause", forceUpdate);
      audio.addEventListener("ended", forceUpdate);
      audio.addEventListener("timeupdate", forceUpdate);
      return () => {
          audio.removeEventListener("play", forceUpdate);
          audio.removeEventListener("pause", forceUpdate);
          audio.removeEventListener("ended", forceUpdate);
          audio.removeEventListener("timeupdate", forceUpdate);
      }
  })

  const play = () => audio.play();
  const pause = () => audio.pause();
  const move = (value: number) => (audio.currentTime = value);
  const skip = () => (audio.currentTime += 15);
  const back = () => (audio.currentTime -= 15);

  const currentTime = Math.floor(audio.currentTime)
  const duration = Math.round(audio.duration)
  return [!audio.paused, currentTime, duration, play, pause, move, skip, back];
}

function getCastType(startedAtString: string, endedAtString:string) {
  let startedAt = new Date(startedAtString);
  let endedAt = new Date(endedAtString);
  let currentTime = new Date()
  console.log(currentTime)
  if (currentTime < startedAt && currentTime < endedAt) {
      return "future"
  }
  if (currentTime >= startedAt && currentTime < endedAt) {
      return "present"
  }
  if (currentTime > startedAt && currentTime >= endedAt) {
      return "past"
  }
}
