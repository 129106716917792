import { DynamicLinkBuilder } from "../store/DynamicLinkBuilder";

interface DynamicLinkConfig{
    castDeepLink:string;
    subDomain:string;
    iosBundleID:string;
    androidPackageName:string;
    inflowUrl:string;
    isForcedRedirectEnabled:boolean;
    homepage:string;
}
export const dynamicLinkConfig: DynamicLinkConfig = {
    castDeepLink: "https://taverniosapp/cast",
    subDomain: "https://taverniosapp.page.link/",
    iosBundleID: "Tavern.ios-app",
    androidPackageName: "Tavern.android.app",
    inflowUrl: "https://tavern.co.jp/inflow.html",
    isForcedRedirectEnabled: true,
    homepage: "https://tavern.co.jp"
}