import { FC } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from 'styled-components';

import tavernLogoImg from '../images/tavernLogo.jpg';

import { WatchAppButton } from '../component/WatchAppButton';

import { DynamicLinkBuilder } from "../store/DynamicLinkBuilder";

type Props = {} & RouteComponentProps<{
    castId: string;
}>;

const TavernLogo = styled.img`
    width: 120px;
    margin: 20px;
`
const Text = styled.div`
    margin: 15px;
`

export const PresentListenPage: FC<Props> = (props:Props) =>{
    const castId = props.match.params.castId
    const dynamicLink = new DynamicLinkBuilder(castId,"web_present_listen").getUrl()
    console.log(dynamicLink.toString())
    return (
        <>
            <TavernLogo src={tavernLogoImg}/>
            <Text>現在通話中のキャストはWebでは視聴できません。</Text>
            <WatchAppButton transitionLink={dynamicLink.toString()} />
        </>
    )
}