import styled from "styled-components"

type Props = {
    transitionLink:string
}

const WatchAppButtonStyle = styled.a`
position: absolute;
color: red;
display: block;
width:  97%;
border: 2px solid orange;
font-size:  20px;
color: orange;
font-weight: bold;
padding-top: 5%;
padding-bottom: 5%;
border-radius: 4px;
transition: .4s;
text-align:  center;
text-decoration: none;
margin: auto;
left: 0;
top: 0;
right: 0;
bottom: 30%;
height: 35px;
&:hover{
    background-color: orange;
    border-color: orange;
    color: #FFF;
}
`

export const WatchAppButton:React.FC<Props> = ({transitionLink}) => {
    return (
        <WatchAppButtonStyle href={transitionLink}>
            アプリでキャストを見る
        </WatchAppButtonStyle>
    )
}