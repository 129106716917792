import { RouteComponentProps } from "react-router-dom"
import {FC, useEffect, useMemo, useRef, useState} from "react"
import Hls from "hls.js"
import {Cast, getCast} from "../api/cast"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import H5AudioPlayer from "react-h5-audio-player"
import { VStack, Image } from '@chakra-ui/react'

export const PreviewPage: FC<{} & RouteComponentProps<{ castId: string }>> = ({ match, location }) => {
  const castId = match.params.castId
  const playlist = `https://api.tavern.co.jp/v3/archive/${castId}/playlist.m3u8`
  const defaultBackgroundImage = "https://share.tavern.co.jp/images/default-background.png"

  const [cast, setCast] = useState<Cast | null>(null)
  const isSupportBrowser = useMemo(() => Hls.isSupported(), [])
  const ref = useRef<H5AudioPlayer>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const audio = ref.current?.audio.current
    // const audio = videoRef.current
    if (!audio) {
      return
    }
    if (isSupportBrowser) {
      const hls = new Hls()
      hls.loadSource(playlist)
      // @ts-ignore
      hls.attachMedia(audio)
      hls.on(Hls.Events.MANIFEST_PARSED, async () => {
        await audio.play()
      });
      return () => {
        hls.removeAllListeners()
        hls.stopLoad()
      }
    } else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
      audio.src = playlist
      audio.addEventListener('canplay', async () => {
        await audio.play()
      })
    }
  }, [castId, isSupportBrowser, playlist, ref])

  useEffect(() => {
    (async () => {
      try {
        const res = await getCast(castId)
        setCast(res.data.Cast)
      } catch (e) {
        alert(e)
      } finally {
      }
    })()
  }, [castId])

  function backgroundImageUri(): string {
    if (!cast) return defaultBackgroundImage
    if (cast.ThumbnailImageUri !== "") return  cast.ThumbnailImageUri
    if (cast.Author.IconImageUri !== "") return  cast.Author.IconImageUri
    return defaultBackgroundImage
  }

  async function togglePlay() {
    const audio = ref.current?.audio.current
    if (!audio) return
    if (audio.paused) await audio.play()
    else await audio.pause()
  }

  const style = {
    width: "100%",
    height: "auto",
    verticalAlign: "top"
  }


  return (
    <>
      <script src="https://cdn.jsdelivr.net/npm/hls.js@latest"/>
      <VStack
        align="center"
        width="100%"
        height="auto"
      >
        <Image
          objectFit="cover"
          width="100%"
          src={backgroundImageUri()}
        />
        <AudioPlayer
          ref={ref}
          src={playlist}
          style={{width: "100%"}}
          onPlay={e => console.log("onPlay")}
        />
      </VStack>
    </>
  )
}
