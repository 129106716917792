import moment from 'moment';
//styleの定義
const descriptionStyle = {
  marginLeft: '15px',
  marginTop:'10%',
  fontSize:'15px',
}

const dateStyle = {
  marginLeft: '15px',
  marginTop :'2%',
  fontSize:'15px',
}

const flex = {
  display : 'flex',
  margin : '1%'
}

const playCountStyle = {
  height:'30px',
  marginLeft : '2%'
}

const countStyle = {
  marginTop:'1%',
  marginLeft:'1%',
  marginBottom:'1%'
}

export const UserSetDescription = (setDescription) => {
  const {Explanation, EndedAt} = setDescription;
  return(
    <>
      <div style={descriptionStyle}>{Explanation}</div>
      <div style={dateStyle}>{formatDate(new Date(EndedAt))}</div>
    </>
  )
}

function formatDate(date) {
  const now = moment(date)
  return now.format("yyyy/MM/DD HH:mm")
}